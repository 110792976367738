import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlusCircle, faHourglassHalf, faBell } from '@fortawesome/free-solid-svg-icons';

import ItemCard from './ItemCard';
import WishListRow from './WishListRow'
import ItemDetailsModal from './ItemDetailsModal';
import WishDetailsModal from './WishDetailsModal';
import { useAuth } from './AuthContext'; // Access previewMode

import { useUser } from './UserContext';
import useDebounce from './useDebounce';
import { CATEGORIES } from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';

import AddItemModal from './AddItemModal'; // You will create this component

import AddWishModal from './AddWishModal'; // You will create this component
import './RecordList.css'

function RecordList({ type, sectionLabel }) {
    const { userId } = useUser();
    const { previewMode } = useAuth(); // Check if in preview mode
    const [showPreviewNotification, setShowPreviewNotification] = useState(previewMode);

    const [category, setCategory] = useState('All');
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [showMyLibrary, setShowMyLibrary] = useState(false);
    const [giveawayOption, setGiveawayOption] = useState(false);
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showAddWishModal, setShowAddWishModal] = useState(false);


    const isWishlist = type === 'wishes';

    // New state for infographics
    const [stats, setStats] = useState({
        totalAvailable: 0,
        totalTransacted: 0,
        totalCo2Saved: 0
    });

    useEffect(() => {
        fetchEntities();
        fetchStatistics();
    }, [userId, category, debouncedSearchQuery, showMyLibrary, giveawayOption]); // Dependencies that control re-fetching

    const fetchEntities = () => {
        const url = isWishlist ? '/api/wishlist' : '/api/items';

        const giveaway = giveawayOption;
        const params = {
            userId,
            giveaway,
            showMyLibrary,
            category,
            searchQuery: debouncedSearchQuery,
        };

        axios.get(url, { params })
            .then(response => {
                setItems(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(`Error fetching ${isWishlist ? 'wishlist' : 'items'}:`, error);
                setIsLoading(false);
            });
    };


    const fetchStatistics = () => {

        const giveaway = giveawayOption;
        axios.get('/api/item-stats', { params: { userId, giveaway } })
            .then(response => {
                setStats({
                    totalAvailable: response.data.total_available,
                    totalTransacted: response.data.total_transacted,
                    totalCo2Saved: null
                });
            })
            .catch(error => console.error('Error fetching item statistics:', error));
    };



    const handleItemSelect = item => {
        setSelectedItem(item);
    };

    const handleItemDeleted = (deletedItemId) => {
        setItems(currentItems => currentItems.filter(item => item.id !== deletedItemId));
    };


    console.log('Rendering ItemList component with showMyLibrary:', showMyLibrary);
    console.log('Current items displayed:', selectedItem);

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="container mt-4 position-relative">
            {/* Preview Mode Notification */}
            {showPreviewNotification && (
                <div className="preview-notification">
                    <span>
                        <FontAwesomeIcon icon={faBell} />{' '}
                        You are currently in <strong>preview mode</strong>. Some functionalities are limited.{' '}
                        <a href="https://communitycycle.app/login" className="login-link">Login here</a>
                    </span>
                    <button
                        className="close-notification"
                        onClick={() => setShowPreviewNotification(false)}
                        aria-label="Close notification"
                    >
                        ✕
                    </button>
                </div>
            )}


            {/* Statistics Section */}
            <div className="statistics-container">
                <div className="stat-card">
                    <h3 className={previewMode ? 'blurred' : ''}>
                        {previewMode ? 257 : stats.totalAvailable}
                    </h3>
                    <p>Available</p>
                </div>
                <div className="stat-card">
                    <h3 className={previewMode ? 'blurred' : ''}>
                        {previewMode ? 71 : stats.totalTransacted}
                    </h3>
                    <p>Transacted</p>
                </div>
                <div className="stat-card">
                    <h3 className={previewMode ? 'blurred' : ''}>
                        <FontAwesomeIcon icon={faHourglassHalf} />{' '}
                        {previewMode ? 3245 : stats.totalCo2Saved}
                    </h3>
                    <p>CO2 Impact</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4">
                    <select className="form-select form-select-lg custom-select-size fs-6" value={category} onChange={e => setCategory(e.target.value)}>
                        {CATEGORIES.map(cat => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>

                <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="Search items..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                </div>

                <div className="option-container">
                    {!isWishlist && (
                        <>
                            <button
                                className={`option-button ${!giveawayOption ? 'active' : ''}`}
                                onClick={() => setGiveawayOption(false)}>
                                Borrow
                            </button>
                            <button
                                className={`option-button ${giveawayOption ? 'active' : ''}`}
                                onClick={() => setGiveawayOption(true)}>
                                Keep
                            </button>
                        </>
                    )}
                </div>

                <div className="switch-label">
                    <label htmlFor="toggle-switch-listings">
                        {showMyLibrary? 'Mine' : 'Others'}
                    </label>
                    <label className="switch">
                        <input
                            type="checkbox"
                            id="toggle-switch-listings"
                            checked={showMyLibrary}
                            onChange={() => setShowMyLibrary(!showMyLibrary)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>


            </div>

            <div className="row mt-3">
                <div className="d-flex flex-wrap justify-content-around">
                    {items.map(item => isWishlist ?
                        <WishListRow key={item.id} wishlist={item} onItemSelect={() => handleItemSelect(item)} /> :
                        <ItemCard key={item.id} item={item} onItemSelect={() => handleItemSelect(item)} />
                    )}
                </div>
            </div>

            {!previewMode && !showAddItemModal && !selectedItem && !showAddWishModal && (
                <>
                    <button onClick={() => setShowAddItemModal(true)} className="btn btn-primary fab-button">
                        <FontAwesomeIcon icon={faPlusCircle} />  {giveawayOption ? 'Offer' : 'Lend'}
                    </button>
                    <button onClick={() => setShowAddWishModal(true)} className="btn btn-primary fab-button-wish">
                        <FontAwesomeIcon icon={faPlusCircle} />  {'Wish'}
                    </button>
                </>
            )}

            {selectedItem && (isWishlist ?
                <WishDetailsModal
                    item={selectedItem}
                    onClose={() => setSelectedItem(null)}
                    onDelete={handleItemDeleted}

                    isOwner={userId === selectedItem.owner_id}
                /> :
                <ItemDetailsModal
                    item={selectedItem}
                    onClose={() => setSelectedItem(null)}
                    onDelete={handleItemDeleted}

                    isOwner={userId === selectedItem.owner_id}
                />

            )}

            {showAddItemModal && !selectedItem && !showAddWishModal && (
                <AddItemModal
                    show={showAddItemModal}
                    onClose={() => setShowAddItemModal(false)}
                    giveaway_flag={giveawayOption}
                />
            )}

            {showAddWishModal && !selectedItem && !showAddItemModal && (
                <AddWishModal
                    show={showAddWishModal}
                    onClose={() => setShowAddWishModal(false)}
                    giveaway_flag={giveawayOption}
                    type={'wish'}
                />
            )}

        </div>
    );
}

export default RecordList;
