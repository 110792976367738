// Items.js
import React from 'react';
import RecordList from './RecordList';

function Items({ previewMode }) {
    // Define the type within the Items component
    const type = 'items'; // This sets the type to 'items'
    
    return <RecordList type={type} sectionLabel={type === 'items' ? "Items" : "Wishes"} />;
}

export default Items;
