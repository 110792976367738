import React, { useState, useEffect } from 'react';
import { API_URL } from './constants';

function EditSharedWithModal({ itemId, userId, currentShared, onClose, onUpdate }) {
  const [groups, setGroups] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(new Set());
  const [selectedConnections, setSelectedConnections] = useState(new Set());
  const [activeSection, setActiveSection] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchGroupsAndConnections();
  }, []);

  useEffect(() => {
    // Preselect current groups and individuals
    const groupIds = currentShared.filter(s => s.type === 'group').map(s => parseInt(s.id));
    const connectionIds = currentShared.filter(s => s.type === 'individual').map(s => parseInt(s.id));
    setSelectedGroups(new Set(groupIds));
    setSelectedConnections(new Set(connectionIds));
  }, [currentShared]);

  const fetchGroupsAndConnections = async () => {
    try {
      const groupsResponse = await fetch(`${API_URL}/user/${userId}/groups`);
      const groupsData = await groupsResponse.json();
      setGroups(groupsData);

      const connectionsResponse = await fetch(`${API_URL}/user/${userId}/connections`);
      const connectionsData = await connectionsResponse.json();
      setConnections(connectionsData);
    } catch (error) {
      console.error('Error fetching groups and connections:', error);
      setError('Error fetching groups and connections');
    }
  };

  const toggleGroupSelection = groupId => {
    setSelectedGroups(prev => {
      const newGroups = new Set(prev);
      if (newGroups.has(groupId)) {
        newGroups.delete(groupId);
      } else {
        newGroups.add(groupId);
      }
      return newGroups;
    });
  };

  const toggleConnectionSelection = connectionId => {
    setSelectedConnections(prev => {
      const newConnections = new Set(prev);
      if (newConnections.has(connectionId)) {
        newConnections.delete(connectionId);
      } else {
        newConnections.add(connectionId);
      }
      return newConnections;
    });
  };

  const toggleActiveSection = section => {
    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const groupsArray = Array.from(selectedGroups);
      const connectionsArray = Array.from(selectedConnections);

      const response = await fetch(`${API_URL}/api/items/${itemId}/shared-with`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId,
          groups: groupsArray,
          connections: connectionsArray
        }),
        credentials: 'include'
      });
      

      if (response.ok) {
        const data = await response.json();
        // data should contain updated lists of groups and connections
        onUpdate(data.groups, data.connections);
        alert('Shared With updated successfully.');
        onClose();
      } else {
        const errData = await response.json();
        alert(errData.message || 'Failed to update shared with');
      }
    } catch (error) {
      console.error('Error updating shared with:', error);
      alert('Error updating shared with');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal" onClick={e => e.stopPropagation()}>
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-btn">&#10005;</button>
        </div>
        <div className="form-step">
          <h3>Edit Shared With</h3>
          <div className="recipient-toggle">
            <h4 className="toggle-header" onClick={() => toggleActiveSection('groups')}>Groups</h4>
            <h4 className="toggle-header" onClick={() => toggleActiveSection('individuals')}>Individuals</h4>
          </div>
          <div className="recipient-details">
            {!isSubmitting && activeSection === 'groups' && (
              <div className="detail-item">
                {groups.map(group => (
                  <div key={group.id}
                       className={`card ${selectedGroups.has(group.id) ? 'selected' : ''}`}
                       onClick={() => toggleGroupSelection(group.id)}>
                    {group.group_name}
                  </div>
                ))}
              </div>
            )}
            {!isSubmitting && activeSection === 'individuals' && (
              <div className="detail-item">
                {connections.map(connection => (
                  <div key={connection.id}
                       className={`card ${selectedConnections.has(connection.id) ? 'selected' : ''}`}
                       onClick={() => toggleConnectionSelection(connection.id)}>
                    {connection.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="button-container">
            <button onClick={onClose} className="btn btn-secondary">Cancel</button>
            <button onClick={handleSubmit} className="btn btn-primary">Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSharedWithModal;
