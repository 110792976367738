import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Feedback.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';

function Feedback() {
  const [feedbackList, setFeedbackList] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  const [sortOption, setSortOption] = useState('upvotes'); // Default sort by upvotes
  const { userId } = useUser();

  const fetchFeedback = async () => {
    try {
      const res = await axios.get('/api/feedback', {
        params: { sort: sortOption }
      });
      setFeedbackList(res.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching feedback:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, [sortOption]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) return;

    try {
      const res = await axios.post('/api/feedback', { userId, content, anonymous });
      setFeedbackList((prev) => [res.data, ...prev]);
      setContent('');
      setAnonymous(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleUpvote = async (id) => {
    try {
      const res = await axios.post('/api/feedback/upvote', { id, userId });
      if (res.data.message) {
        alert(res.data.message);
        return;
      }

      setFeedbackList((prev) =>
        prev.map((item) => (item.id === id ? res.data : item))
      );

      setFeedbackList((prev) =>
        [...prev].sort((a, b) => {
          if (sortOption === 'upvotes') {
            return b.upvotes - a.upvotes || new Date(b.created_at) - new Date(a.created_at);
          } else {
            return new Date(b.created_at) - new Date(a.created_at);
          }
        })
      );
    } catch (error) {
      console.error('Error upvoting feedback:', error);
    }
  };

  if (loading) return <div className="loading-state">Loading feedback...</div>;

  return (
    <div className="feedback-container">
      <h2>Feedback & Suggestions</h2>
      <p className="intro-text">
        We value your feedback! Please share your suggestions to help us improve.
        Others can upvote suggestions they like.
      </p>

      <form className="feedback-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Your Suggestion:</label>
          <textarea
            placeholder="Write your suggestion here..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={4}
          />
        </div>

        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            id="anonymous"
            checked={anonymous}
            onChange={() => setAnonymous(!anonymous)}
          />
          <label htmlFor="anonymous">Post Anonymously</label>
        </div>

        <button type="submit" className="submit-button">
          <FontAwesomeIcon icon={faPaperPlane} /> Submit
        </button>
      </form>

      <div className="sort-buttons">
        <button
          className={`sort-button ${sortOption === 'upvotes' ? 'active' : ''}`}
          onClick={() => setSortOption('upvotes')}
        >
          Most Upvoted
        </button>
        <button
          className={`sort-button ${sortOption === 'recent' ? 'active' : ''}`}
          onClick={() => setSortOption('recent')}
        >
          Most Recent
        </button>
      </div>

      <h3 className="list-heading">All Feedback</h3>
      <div className="feedback-list">
        {feedbackList.length === 0 ? (
          <p>No suggestions yet. Be the first to contribute!</p>
        ) : (
          feedbackList.map((item) => (
            <div key={item.id} className="feedback-item">
              <div className="feedback-content">
                <p className="feedback-text">{item.content}</p>
                <p className="feedback-meta">
                  Posted by {item.anonymous ? 'Anonymous' : (item.name || 'Anonymous')}
                </p>
              </div>
              <div className="feedback-actions">
                <button className="upvote-button" onClick={() => handleUpvote(item.id)}>
                  <FontAwesomeIcon icon={faThumbsUp} /> {item.upvotes}
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Feedback;
