import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h2>How CommunityCycle Works:</h2>
      <ul>
        <li>
          <strong>Borrow & Share:</strong> CommunityCycle thrives on the principle of sharing what we have and borrowing what we need. Imagine borrowing a camping tent for your next trip or finding a new home for your gently used kitchen mixer that's gathering dust. Our community's generosity drives our platform, making it possible to find items for temporary or long-term use.
        </li>
        <li>
          <strong>Wish List:</strong> Can't find what you’re looking for? Your wishes drive our community forward. Post your needs to inspire others to share items they hadn't considered before, thus enhancing our shared resource pool.
        </li>
      </ul>
    </div>
  );
};

export default About;
