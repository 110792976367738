import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useUser } from './UserContext';
import Switch from 'react-switch';
import { useSpring, animated } from 'react-spring';

import './Settings.css'; // Ensure consistent styling

function Settings() {
    const { userId } = useUser();
    const [groups, setGroups] = useState([]);
    const [connections, setConnections] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState(null);
    const [searchIdentifier, setSearchIdentifier] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch subscribed groups
                const groupsRes = await axios.get(`/api/groups/${userId}`);
                setGroups(groupsRes.data.sort((a, b) => b.is_subscribed - a.is_subscribed));

                // Fetch active connections
                const connectionsRes = await axios.get('/api/connections/user', {
                    params: { userId }
                });
                setConnections(connectionsRes.data.sort((a, b) => b.is_connected - a.is_connected));

                // Fetch pending requests
                const requestsRes = await axios.get('/api/connections/requests', {
                    params: { userId } // Add this line
                });
                setPendingRequests(requestsRes.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [userId]);

    const handleGroupAction = async (groupId, action) => {
        try {
            await axios.post('/api/groups/manage', { userId, groupId, action });
            setGroups((prevGroups) =>
                prevGroups.map((group) =>
                    group.id === groupId ? { ...group, is_subscribed: action === 'subscribe' } : group
                ).sort((a, b) => b.is_subscribed - a.is_subscribed)
            );
        } catch (error) {
            console.error('Failed to manage group:', error);
        }
    };

    const handleConnectionAction = async (connectUserId, action) => {
        try {
            await axios.post('/api/connections/manage', { userId, connectUserId, action });
            setConnections((prevConnections) =>
                prevConnections.map((conn) =>
                    conn.id === connectUserId ? { ...conn, is_connected: action === 'add' } : conn
                ).sort((a, b) => b.is_connected - a.is_connected)
            );
        } catch (error) {
            console.error('Failed to manage connection:', error);
        }
    };

    const handleSendRequest = async () => {
        if (!searchIdentifier) {
            setResponseMessage('Please enter a full name or email.');
            return;
        }
        setLoading(true);
        try {
            const res = await axios.post('/api/connections/request', {
                identifier: searchIdentifier,
                userId: userId // Include userId
            });
            setResponseMessage(res.data.message);
            setSearchIdentifier('');
        } catch (error) {
            console.error('Failed to send connection request:', error);
            setResponseMessage('Failed to send the request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleRequestResponse = async (requestId, action) => {
        try {
            // Find the accepted request before modifying pendingRequests
            const acceptedRequest = pendingRequests.find((r) => r.id === requestId);

            const res = await axios.post('/api/connections/respond', {
                requestId,
                action,
                userId: userId
            });

            setResponseMessage(res.data.message);

            // Now remove the handled request from pending requests
            setPendingRequests((prev) => prev.filter((req) => req.id !== requestId));

            // If the request was accepted, establish the connection
            if (action === 'accept' && acceptedRequest) {
                const { sender_id, receiver_id } = res.data;
                const connectUserId = sender_id === userId ? receiver_id : sender_id;

                // If the user isn't already in connections, add them using acceptedRequest.sender_name
                setConnections((prev) => {
                    const exists = prev.some((conn) => conn.id === connectUserId);
                    if (!exists) {
                        return [...prev, { id: connectUserId, name: acceptedRequest.sender_name, is_connected: false }];
                    }
                    return prev;
                });

                await handleConnectionAction(connectUserId, 'add');
            }
        } catch (error) {
            console.error('Failed to respond to request:', error);
            setResponseMessage('Failed to process the request. Please try again.');
        }
    };



    // Toggle active section for animations
    const toggleSection = (sectionName) => {
        setActiveSection(activeSection === sectionName ? null : sectionName);
    };

    const slideInStyles = useSpring({
        from: { height: 0, opacity: 0 },
        to: {
            height: activeSection ? 'auto' : 0,
            opacity: activeSection ? 1 : 0,
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    const subscribedGroups = groups.filter((group) => group.is_subscribed).length;
    const connectedIndividuals = connections.filter((conn) => conn.is_connected).length;

    return (
        <div className="settings-container">
            <h5>Network & Subscriptions</h5>
            <div className="dashboard-overview mb-3 p-2 bg-light border rounded d-flex flex-wrap justify-content-between">
                {/* Groups Section */}
                <div
                    className={`card text-center p-2 flex-fill m-1 clickable ${activeSection === 'groups' ? 'selected' : ''}`}
                    onClick={() => toggleSection('groups')}
                >
                    <h6 className="mb-1">
                        <FontAwesomeIcon icon={faUserFriends} /> Groups
                    </h6>
                    <div className="lead">{subscribedGroups} Subscribed</div>
                    <small>{groups.length} Total</small>
                </div>

                {/* Connections Section */}
                <div
                    className={`card text-center p-2 flex-fill m-1 clickable ${activeSection === 'connections' ? 'selected' : ''}`}
                    onClick={() => toggleSection('connections')}
                >
                    <h6 className="mb-1">
                        <FontAwesomeIcon icon={faExchangeAlt} /> Connections
                    </h6>
                    <div className="lead">{connectedIndividuals} Connected</div>
                    <small>{connections.length} Total</small>
                </div>
            </div>

            {activeSection === 'groups' && (
                <animated.div style={slideInStyles}>
                    {groups.map((group) => (
                        <div key={group.id} className="card">
                            <p>{group.group_name}</p>
                            <p>{group.description}</p>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleGroupAction(group.id, group.is_subscribed ? 'unsubscribe' : 'subscribe');
                                }}
                                className={group.is_subscribed ? 'unsubscribe-button' : 'subscribe-button'}
                            >
                                {group.is_subscribed ? 'Unsubscribe' : 'Subscribe'}
                            </button>
                        </div>
                    ))}
                </animated.div>
            )}

            {activeSection === 'connections' && (
                <animated.div style={slideInStyles}>
                    {connections.map((conn) => (
                        <div key={conn.id} className={`card ${conn.is_connected ? 'connected' : 'disconnected'}`}>
                            <p>{conn.name}</p>
                            <Switch
                                onChange={() => handleConnectionAction(conn.id, conn.is_connected ? 'remove' : 'add')}
                                checked={conn.is_connected}
                                onColor="#4CAF50"
                                offColor="#ccc"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={40}
                            />
                        </div>
                    ))}
                </animated.div>
            )}

            {/* Find and Connect Section */}
            <h5>Find and Connect</h5>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Enter full name or email"
                    value={searchIdentifier}
                    onChange={(e) => setSearchIdentifier(e.target.value)}
                    className="search-input"
                />
                <button onClick={handleSendRequest} disabled={loading} className="search-button">
                    {loading ? 'Sending...' : 'Send Request'}
                </button>
            </div>
            {responseMessage && <p className="response-message">{responseMessage}</p>}

            {/* Pending Requests Section */}
<h5 className="pending-requests-heading">
  {pendingRequests.length === 0
    ? 'No Pending Requests'
    : `${pendingRequests.length} Pending Requests`}
</h5>
{pendingRequests.length === 0 ? (
  <p>No pending requests at the moment.</p>
) : (
  <div className="pending-requests">
    {pendingRequests.map((request) => (
      <div key={request.id} className="request-card">
        <p>
          {request.sender_name}
          {/* Display sender email only if it exists */}
          {request.sender_email && ` (${request.sender_email})`}
        </p>
        <div className="request-actions">
          <button
            onClick={() => handleRequestResponse(request.id, 'accept')}
            className="accept-button"
          >
            Accept
          </button>
          <button
            onClick={() => handleRequestResponse(request.id, 'decline')}
            className="decline-button"
          >
            Decline
          </button>
        </div>
      </div>
    ))}
  </div>
)}


        </div>
    );
}

export default Settings;
